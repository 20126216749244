import React from "react";
import { Box } from "@mui/material";
import logo from "../assets/logo.png";

const Logo = () => {
  return (
    <Box
      sx={{
        fontSize: "2.5rem",
        textAlign: "center",
        padding: "0.5rem 0 0",
        color: "#333",
        fontWeight: "bold",
      }}
    >
      <img
        src={logo}
        alt="ml"
        style={{ width: "160px" }}
        className="mx-auto pt-1"
      />
    </Box>
  );
};

export default Logo;
