import { Box, Typography } from "@mui/material";
import React from "react";

const NotificationMessage = ({ type, order_id }) => {
  return (
    <Box
      sx={{
        background:
          type == "delay"
            ? "#c241f3"
            : type == "new"
            ? "#c241f3"
            : type == "change"
            ? "#6ab4d9"
            : "#eee",
        padding: "0.7rem ",
        borderRadius: "10px",
        marginTop: "1rem",
        color: "white",
      }}
    >
      <Typography
        sx={{
          fontSize: "0.9rem",
        }}
      >
        Order{" "}
        <span
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {order_id}
        </span>{" "}
        {type === "delay"
          ? "Has Been Delayed"
          : type === "new"
          ? "Is New"
          : type === "change"
          ? "Have Been Changed"
          : ""}
      </Typography>
    </Box>
  );
};

export default NotificationMessage;
