import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Protected from "./Protected";
import CheckUuid from "./components/CheckUuid";
import ErrorPage from "./components/ErrorPage";
import { ErrorBoundary } from "react-error-boundary";

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={(e) => {
          console.log(e);
        }}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/check/:uuid" element={<CheckUuid />} />

          <Route element={<Protected />}>
            <Route element={<Dashboard />} path="/dashboard" />
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
