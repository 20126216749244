import { Box, Typography, Grid, Stack, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { request } from "../api/request";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import Loader from "../components/loader/loader";
import Heading from "./Heading";
import { useErrorBoundary } from "react-error-boundary";

const Tables = () => {
  const { showBoundary } = useErrorBoundary();

  const [help, setHelp] = useState([]);
  const getTables = useQuery({
    queryKey: ["get-tables"],
    queryFn: () => {
      return request({
        url: `/table/branch/${localStorage.getItem("branch_id")}`,
      });
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const pusher = new Pusher("897190819c4cec71fdc0", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe(
      `waiter.${localStorage.getItem("branch_id")}`
    );

    channel.bind("CallWaiter", (data) => {
      console.log("data", data);
      setHelp((prev) => {
        return [...prev, data?.table.id];
      });
    });

    return () => {
      channel.unbind();
      pusher.disconnect();
    };
  }, []);

  console.log(help);

  // if (getTables.isLoading) {
  //   return (
  //     <Box sx={{ transform: "scale(0.7)" }}>
  //       <Loader />
  //     </Box>
  //   );
  // }
  if (getTables.isError) {
    showBoundary(getTables.error);
    return;
  }
  const tables = getTables.data?.data.data;
  return (
    <div className="bg-slate-200 min-h-screen ">
      <Box style={{ padding: "2rem 0rem" }}>
        <Heading width={"40%"} size={"1.5rem"}>
          Tables
        </Heading>
        {getTables.isLoading ? (
          <Box sx={{ transform: "scale(0.7)" }}>
            <Loader />
          </Box>
        ) : (
          <Grid justifyContent={"center"} alignItems={"center"} container>
            {tables.map((item) => {
              return (
                <Grid sx={{ padding: "1rem 0" }} item xs={12}>
                  <Stack
                    sx={{
                      border: "1px solid #6664de",
                      borderRadius: "3px",
                      padding: "0.5rem",
                      width: "80%",
                      margin: "0 auto",
                      cursor: "pointer",
                    }}
                    direction={"column"}
                    gap={1}
                    justifyContent={"space-between"}
                  >
                    <button
                      className={`${
                        help.includes(item.id)
                          ? "tableAnimation click"
                          : "click"
                      }`}
                      variant="contained"
                      style={{
                        textAlign: "center",
                        background: "#6664de",
                        padding: "0.2rem 1rem",
                        borderRadius: "10px",
                        color: "white",
                        fontSize: "0.8rem",
                        margin: "auto auto",
                        transition: "0.1s",
                      }}
                      onClick={() => {
                        window.location.replace(
                          `https://user.restu-plus.com/?table_id=${item.id}`
                        );
                      }}
                    >
                      {item.table_num}
                    </button>
                    {help.includes(item.id) && (
                      <button
                        style={{
                          padding: "0",
                          fontSize: "0.8rem",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setHelp((prev) => {
                            const newArr = prev.filter((table) => {
                              return table !== item.id;
                            });
                            return newArr;
                          });
                        }}
                      >
                        serve !
                      </button>
                    )}
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default Tables;
